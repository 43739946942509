<template>
  <div class>
    <div class="d-flex mb-2">
      <span class="page-header-title">User Registration List</span>
    </div>
    <CCard>
      <CCardBody>
        <div class="d-md-flex d-sm-flex justify-content-end mt-3">
          <div class="mr-0 mr-lg-3 mr-md-3 mr-xl-3 mr-sm-2">
            <b-form-select
              class="role_select_box mb-2"
              v-model="selectedRoleType"
              @change="selectRolesType(selectedRoleType)"
              :options="usersRoleData"
            ></b-form-select>
          </div>
          <div class="mr-0 mr-lg-3 mr-md-3 mr-xl-3 mr-sm-2">
            <b-form-select
              v-model="selectedUserType"
              @change="selectUserType(selectedUserType)"
              :options="optionUserItems"
              class="mb-2"
            ></b-form-select>
          </div>

          <div>
            <b-input-group class="mb-2">
              <template #append>
                <b-input-group-text @click="OnSearch(searchText)">
                  <CIcon name="cil-search" class="cicon cursor" />
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="searchText"
                placeholder="Search"
                v-on:keyup.enter="OnSearch(searchText)"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>

        <div class="table-responsive mt-2">
          <CustomTableUser
            class="vertical-align-middle table-responsive account_table"
            :items="usersList"
            hover
            striped
            border
            :small="'small'"
            fixed
            caption
            :fields="fields"
            :per-page="50"
            :total="total"
            :loadTableData="loadTableData"
            :rowSelectHandler="rowClickHandler"
            :onRowDeleteHandler="deleteTableData"
            :onRowEditHandler="editTableData"
            :onRowSpamHandler="editSpam"
            :rowFliterHandler="rowFliterHandler"
            :onRowUnSpamHandler="editUnSpam"
            :onRowApprovalHandler="editApprove"
            :currentPagination="
            usersFilters.start == 0 ? 1 : currentPageOfUserTable
          "
          />
        </div>

        <b-modal
          hide-header
          id="spamUser"
          title="Are you sure want to make this user as spam?"
          ok-title="Yes"
          cancel-title="Cancel"
          @ok="spamSelectedUser"
        >
          <p class="my-4">
            Are you sure want to make
            <b>{{ selectedUserEmail }}</b> as spam?
          </p>
        </b-modal>

        <b-modal
          hide-header
          id="unSpamUser"
          title="Are you sure want to make this user as unspam ?"
          ok-title="Yes"
          cancel-title="Cancel"
          @ok="unSpamSelectedUser"
        >
          <p class="my-4">
            Are you sure want to mark
            <b>{{ selectedUserEmail }}</b> as active?
          </p>
        </b-modal>

        <b-modal
          hide-header
          id="approveUser"
          title="Are you sure want to make this user as approve?"
          ok-title="Yes"
          cancel-title="Cancel"
          @ok="approveSelectedUser"
        >
          <p class="my-4">
            Are you sure want to approve
            <b>{{ selectedUserEmail }}</b>?
          </p>
        </b-modal>

        <b-modal
          hide-header
          id="editUser"
          title="Are you sure want to change the role ?"
          ok-title="Yes"
          cancel-title="Cancel"
        >
          <p class="my-4">
            Are you sure want to change the role of
            <b>{{ selectedUserEmail }}</b>?
          </p>
          <div class="col-lg-6">
            <b-form-select v-model="editRoleOfUser" :options="usersRoleData" class="mr-3"></b-form-select>
            <show-errors
              :control="$v.editRoleOfUser"
              :field="$t('app.template.management.tempalte.name')"
            />
          </div>

          <div class="col-lg-6 mt-2" v-if="editRoleOfUser == 6 || editRoleOfUser == 2">
            <b-form-select
              v-model="mtbModelNameSelected"
              :options="mtbModelNameList"
              value-field="id"
              text-field="name"
              class="mr-3"
            ></b-form-select>
            <show-errors
              :control="$v.mtbModelNameSelected"
              :field="$t('app.template.management.tempalte.name')"
            />
          </div>

          <template v-slot:modal-footer="{}">
            <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
              <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
                <b-button
                  size="md"
                  variant="secondary"
                  class="ml-2 px-2"
                  @click="closeEditModal()"
                >Cancel</b-button>
                <b-button
                  size="md"
                  variant="primary"
                  class="ml-2 px-2"
                  :disabled="editSaveBtn"
                  @click="editRoleType()"
                >Save</b-button>
              </div>
            </div>
          </template>
        </b-modal>

        <b-modal
          hide-header
          id="deletePopupUser"
          class="cancelbtn"
          title="Delete Data"
          ok-title="Yes"
          cancel-title="Cancel"
          @ok="deleteUsers"
        >
          <p class="my-4">
            Are you sure want to delete
            <b>{{ selectedUserEmail }}</b>?
          </p>
        </b-modal>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { multipleErrorMessages } from "@/shared/utils";
import _ from "lodash";
import {
  USERS_LIST,
  USERS_DELETE,
  USERS_ROLE_UPDATE,
  USERS_ROLE_LIST,
  DELETE_USER_BY_ID
} from "@/store/actions.type";
import CustomTableUser from "@/components/Table/TableUser.vue";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import constants from "@/constants/constants.js";
import { SETTINGS_SORT_LIST } from "../../store/actions.type";
export default {
  name: "UserList",
  components: { CustomTableUser, ShowErrors },
  data() {
    return {
      usersRoleData: [{ value: "", text: "Select Role Type" }],
      optionUserItems: constants.optionUserItems,
      usersList: [],
      searchText: "",
      usersFilters: {
        start: 0,
        limit: 50,
        order_by: "desc",
        sort_by: "created_at",
        status: [0, 4],
        role_id: [1, 2, 3, 6, 7]
      },
      selectedRoleType: "",
      selectedUserType: "",
      editRoleOfUser: "",
      selectedUserName: "",
      selectedUserEmail: "",
      userId: "",
      userStatus: 0,
      currentPage: 1,
      pageStart: 0,
      perPage: 50,
      total: 0,
      sortField: "created_at",
      sortDirection: "desc",
      del_account_name: "",
      currentPageOfUserTable: 1,
      mtbModelNameSelected: null,
      editSaveBtn: false,

      fields: [
        {
          key: "userName",
          sorter: true,
          label: "Name",
          _classes: "tbl-width-50 userName"
        },
        {
          key: "email",
          label: "Email ID",
          sortable: true,
          _classes: "tbl-width-50"
        },
        {
          key: "phone_no",
          label: "Phone Number",
          sorter: false,
          _classes: "tbl-width-50 phone-no"
        },
        {
          key: "company_name",
          label: "Company Name",
          sortable: true,
          _classes: "tbl-width-60 company_name"
        },
        {
          key: "mtb",
          label: "MTB Name",
          sortable: false,
          _classes: "tbl-width-50 mtb_name"
        },
        {
          key: "userAction",
          label: "Action",
          sortable: false,
          _classes: "tbl-width-70 user_action"
        }
      ],
      mtbModelNameList: [],
      mtbModelNameListPlaceholder: [{ id: null, name: "Select MTB Name" }]
    };
  },
  //validation for the fields
  validations: {
    editRoleOfUser: { required },
    mtbModelNameSelected: {
      required: requiredIf(function() {
        return this.editRoleOfUser == 2 || this.editRoleOfUser == 6;
      })
    }
  },
  methods: {
    changeColor() {
      document.documentElement.style.setProperty("--sidebarBgColor", "red");
    },

    /**
     * @method rowFliterHandler
     * @description Method to load table data with filter
     * @author @Jeevan Poojary
     */
    async rowFliterHandler(e) {
      this.sortDirection = e.asc ? "asc" : "desc";
      this.sortField = e.column;
      if (this.sortField == "user_profile") {
        this.sortField = "company_name";
      }
      this.fetchUsersList();
    },

    /**
     * @method rowClickHandler
     * @description Redirect to details
     * @author @Jeevan Poojary
     */
    rowClickHandler(item) {
      this.$store.commit("set", ["selectedUser", btoa(item.id)]);
      this.$router.push({
        name: "usersdetails"
        // params: { id: item.id },
      });
    },

    /**
     * @method editTableData
     * @description Opens edit popup
     * @author @Jeevan Poojary
     */
    editTableData(item) {
      this.selectedUserName = item.name;
      this.selectedUserEmail = item.email;
      this.userId = item.id;
      this.editRoleOfUser = item.role_id;
      this.mtbModelNameSelected = item.mtb_id ? item.mtb_id : null;
      this.$v.editRoleOfUser.$reset();
      this.$v.mtbModelNameSelected.$reset();
      this.$bvModal.show("editUser");
    },

    /**
     * @method editRoleType
     * @description edit role type of users
     * @author @Jeevan Poojary
     */
    async editRoleType() {
      this.$v.$touch();
      if (
        !this.$v.editRoleOfUser.$invalid &&
        !this.$v.mtbModelNameSelected.$invalid
      ) {
        this.editSaveBtn = true;
        let data = await this.$store.dispatch(USERS_ROLE_UPDATE, {
          user_id: btoa(this.userId),
          role_id: this.editRoleOfUser,
          mtb_id: this.mtbModelNameSelected
        });
        this.editSaveBtn = false;
        if (_.get(data, "success", true)) {
          this.$toast.removeAll();
          this.$v.editRoleOfUser.$reset();
          this.$v.mtbModelNameSelected.$reset();
          this.$toast.success(_.get(data, "data.message", ""));
          this.$bvModal.hide("editUser");
          this.fetchUsersList();
          this.userId = "";
          this.selectedUserName = "";
          this.selectedUserEmail = "";
        } else {
          this.$toast.removeAll();
          let message = _.get(data, "data.message", "");
          multipleErrorMessages(this.$toast, message);
        }
      }
      this.mtbModelNameSelected = null;
    },

    /**
     * @method editSpam
     * @description opens edit spam
     * @author @Jeevan Poojary
     */
    editSpam(item) {
      this.selectedUserName = item.name;
      this.selectedUserEmail = item.email;
      this.userId = item.id;
      this.$bvModal.show("spamUser");
    },

    /**
     * @method spamSelectedUser
     * @description mark as spam user
     * @author @Jeevan Poojary
     */
    async spamSelectedUser() {
      if (this.userId) {
        let data = await this.$store.dispatch(USERS_DELETE, {
          id: btoa(this.userId),
          status: 1
        });
        if (_.get(data, "success", true)) {
          this.$toast.removeAll();
          this.$toast.success(_.get(data, "data.message", ""));
          this.userId = "";
          this.selectedUserName = "";
          this.selectedUserEmail = "";
          this.fetchUsersList();
        } else {
          this.$toast.removeAll();
          let message = _.get(data, "data.message", "");
          multipleErrorMessages(this.$toast, message);
        }
      }
    },

    /**
     * @method approveSelectedUser
     * @description mark as approve user
     * @author @Jeevan Poojary
     */
    async approveSelectedUser() {
      if (this.userId) {
        let data = await this.$store.dispatch(USERS_DELETE, {
          id: btoa(this.userId),
          status: 0
        });
        if (_.get(data, "success", true)) {
          this.$toast.removeAll();
          this.$toast.success(_.get(data, "data.message", ""));
          this.userId = "";
          this.selectedUserName = "";
          this.selectedUserEmail = "";
          this.fetchUsersList();
        } else {
          this.$toast.removeAll();
          let message = _.get(data, "data.message", "");
          multipleErrorMessages(this.$toast, message);
        }
      }
    },

    /**
     * @method unSpamSelectedUser
     * @description mark as un spam user
     * @author @Jeevan Poojary
     */
    async unSpamSelectedUser() {
      if (this.userId) {
        let data = await this.$store.dispatch(USERS_DELETE, {
          id: btoa(this.userId),
          status: 0
        });
        if (_.get(data, "success", true)) {
          this.$toast.removeAll();
          this.$toast.success(_.get(data, "data.message", ""));
          this.userId = "";
          this.selectedUserName = "";
          this.selectedUserEmail = "";
          this.fetchUsersList();
        } else {
          this.$toast.removeAll();
          let message = _.get(data, "data.message", "");
          multipleErrorMessages(this.$toast, message);
        }
      }
    },
    /**
     * @method editApprove
     * @description opens edit approve
     * @author @Jeevan Poojary
     */
    editApprove(item) {
      this.selectedUserName = item.name;
      this.selectedUserEmail = item.email;
      this.userId = item.id;
      this.$bvModal.show("approveUser");
    },

    /**
     * @method editUnSpam
     * @description opens edit unspam
     * @author @Jeevan Poojary
     */
    editUnSpam(item) {
      this.selectedUserName = item.name;
      this.selectedUserEmail = item.email;
      this.userId = item.id;
      this.$bvModal.show("unSpamUser");
    },
    /**
     * @method deleteTableData
     * @description opens delete popup
     * @author @Jeevan Poojary
     */
    deleteTableData(item) {
      this.selectedUserName = item.name;
      this.selectedUserEmail = item.email;
      this.userId = item.id;
      this.$bvModal.show("deletePopupUser");
    },

    /**
     * @method deleteUsers
     * @description Deleting users data from list
     * @author @Jeevan Poojary
     */
    async deleteUsers() {
      if (this.userId) {
        let data = await this.$store.dispatch(DELETE_USER_BY_ID, {
          id: btoa(this.userId)
        });
        if (_.get(data, "success", true)) {
          this.$toast.removeAll();
          this.$toast.success(_.get(data, "data.message", ""));
          this.userId = "";
          this.selectedUserName = "";
          this.selectedUserEmail = "";

          if (this.usersList.length == 1) {
            this.pageStart = 0;
            this.currentPage = 1;
            this.perPage = 50;
          }

          this.fetchUsersList();
        } else {
          this.$toast.removeAll();
          let message = _.get(data, "data.message", "");
          multipleErrorMessages(this.$toast, message);
        }
      }
    },

    /**
     * @method selectRolesType
     * @description To handle filter based on selected role
     * @author @Jeevan Poojary
     */
    async selectRolesType(selectedRoleType) {
      if (selectedRoleType == 1) {
        this.usersFilters.role_id = [1];
      } else if (selectedRoleType == 2) {
        this.usersFilters.role_id = [2];
      } else if (selectedRoleType == 3) {
        this.usersFilters.role_id = [3];
      } else if (selectedRoleType == 4) {
        this.usersFilters.role_id = [4];
      } else if (selectedRoleType == 6) {
        this.usersFilters.role_id = [6];
      } else if (selectedRoleType == 7) {
        this.usersFilters.role_id = [7];
      } else {
        this.usersFilters.role_id = [1, 2, 3, 6, 7];
      }
      this.currentPage = 1;
      this.fetchUsersList();
    },

    /**
     * @method selectUserType
     * @description To handle filter based on selected user
     * @author @Jeevan Poojary
     */
    async selectUserType(selectedUserType) {
      if (selectedUserType == 1) {
        this.usersFilters.status = [0, 1, 4];
      } else if (selectedUserType == 2) {
        this.usersFilters.status = [4];
      } else {
        this.usersFilters.status = [0, 4];
      }
      this.currentPage = 1;
      this.fetchUsersList();
    },

    /**
     * @method OnSearch
     * @description To handle on search of table data
     * @author @Jeevan Poojary
     */
    async OnSearch(searchText) {
      this.searchText = searchText;
      this.currentPage = 1;
      this.fetchUsersList();
    },

    /**
     * @method handleStatus
     * @description To handle on change of status
     * @author @Jeevan Poojary
     */
    async handleStatus(evt) {
      if (evt) {
        this.userStatus = true;
        this.usersFilters.status = true;
      } else {
        this.userStatus = false;
        this.usersFilters.status = false;
      }

      this.currentPage = 1;
      this.fetchUsersList();
    },
    /*
    /**
     * @method loadTableData
     * @description Method to load table data
     * @author @Jeevan Poojary
     */
    async loadTableData(currentPage, limit) {
      this.currentPage = currentPage;
      this.pageStart =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.perPage = limit;
      this.currentPageOfUserTable = currentPage;
      this.fetchUsersList();
    },

    /**
     * @method fetchUsersList
     * @description Tt will fetch all users data
     * @author @Jeevan Poojary
     */

    async fetchUsersList() {
      this.usersFilters.start =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.usersFilters.limit = this.perPage;
      this.usersFilters.sort_by = this.sortField;
      this.usersFilters.order_by = this.sortDirection;
      this.isUserLoading = this.$loading.show();
      let data = await this.$store.dispatch(USERS_LIST, {
        filters: this.usersFilters,
        search: this.searchText
      });
      this.isUserLoading.hide();
      if (_.get(data, "success", true)) {
        this.usersList = _.get(data, "list", []);
        this.total = _.get(data, "total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.usersList = [];
        this.total = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    async fetchControllerSortList() {
      this.isLoading = this.$loading.show();
      let data = await this.$store.dispatch(SETTINGS_SORT_LIST, {
        type: 2
      });
      this.isLoading.hide();
      if (_.get(data, "success", true)) {
        this.mtbModelNameList = _.concat(
          ...this.mtbModelNameListPlaceholder,
          ...data
        );
      } else {
        let message = _.get(data, "message", "");
        this.mtbModelNameList = [];

        if (message.length > 0) {
          this.$toast.error({
            message: message
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },
    closeEditModal() {
      this.$bvModal.hide("editUser");
      this.mtbModelNameSelected = null;
    }
  },
  async created() {
    this.fetchUsersList();
    let dropdownRole = await this.$store.dispatch(USERS_ROLE_LIST);
    let roleOptions = [];
    let i;
    for (i = 0; i < dropdownRole.length; i++) {
      roleOptions.push({
        text: dropdownRole[i].name,
        value: dropdownRole[i].id
      });
    }
    this.usersRoleData = _.concat(...this.usersRoleData, roleOptions);
    this.fetchControllerSortList();
  }
};
</script>
<style>
@media (max-width: 426px) {
  .first-check {
    width: 116px !important;
  }
}

.user_action div {
  width: 150px !important;
}

.phone-no div {
  min-width: 120px !important;
}

.mtb_name div {
  min-width: 120px !important;
}

.userName div {
  width: 150px !important;
}

.company_name {
  min-width: 200px !important;
}

.role_select_box {
  min-width: 238px !important;
}
</style>
